angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_company', {
            url: '/configuration/company',
            views: {
                "main": {
                    controller: 'CompanyCtrl',
                    templateUrl: 'web/main/configuration/company/company.tpl.html',
                    controllerAs: 'controller'
                }
            },

            data: {
                pageTitle: 'Company'
            }
        });
    })

    .controller('CompanyCtrl', function ($rootScope, $scope, session, companyService, fingerinkService, swalService, fingerinkSession, changeStampModal) {

        var that = this;

        that.empresa = session.get().user.company;
        that.empresaCopy = angular.copy(that.empresa);
        fingerinkService.getCountries().then(response => that.paises = response.data);

        $scope.$watch("controller.filesCompany", () => (that.filesCompany && that.filesCompany[0]) ? companyService.uploadImageCompany(that.filesCompany[0]).then(response => that.companyImage = response.data) : '');
        companyService.companyImageURL().then(response => that.companyImage = response.data);
        that.upload = () => angular.element(document.querySelector('#inputFileCompany')).click();
        that.changeStamp = () => changeStampModal.openModal().result.then(data => that.stampUrl = data);
        companyService.companyStampURL().then(response => {
            that.stampUrl = response.data;
            that.stampLoaded = true;
        });

        that.updateEmpresa = () => swalService.requestSwal('¿Estás seguro?', 'Al guardar la empresa los datos anteriores se perderán', 'warning', () => companyService.updateEmpresa(that.empresaCopy))
            .then(() => {
                swalService.basicSwal('¡Correcto!', 'Se han editado los datos de usuario para que los cambios sean visibles, por favor, recarga la página.', 'success');
                that.empresa = angular.copy(that.empresa);
                that.editarEmpresa = false;
                fingerinkSession.renoveToken();
            }, () => swalService.basicSwal('Algo ha ido mal', 'Ha habido un error actualizando los datos de la empresa', 'error'));

    });
